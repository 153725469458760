import React, { useEffect } from 'react';
import Link from 'next/link';
import analytics from '@/lib/analytics';
import SpringAmazeIconBlackText from '@/components/Icons/SpringAmazeIconBlackText';
import NextImage from '../NextImage';
import styles from './index.module.css';

const Custom404: React.FC = () => {
  useEffect(() => {
    analytics.track('error_viewed', { errorTitle: '404 Error – Page Not Found' });
  });
  return (
    <div className={styles.container}>
      <p className={styles.errorHeader}>Whoops! This page got lost between aisles.</p>
      <p className={styles.errorDescription}>No worries, embrace the unexpected and shop the latest finds below!</p>
      <div className={styles.errorContent}>
        <div className={styles.errorLinkButton}>
          <NextImage src='/fire.svg' alt='fire icon' width={40} height={40} className={styles.fireIcon} />
          <h4 className={styles.linkText}>
            <Link href='https://www.springtrendshop.com/' type='button' className={styles.exploreLink}>
              Explore hot picks
            </Link>
          </h4>
        </div>
        <NextImage
          src='/graphic.svg'
          alt='different products'
          width={709}
          height={345}
          className='min-w-[115%] lg:min-w-full'
        />
      </div>
      <div className={styles.footer}>
        <span className={styles.footerText}>Powered by</span>
        <div className={styles.footerLogo}>
          <SpringAmazeIconBlackText />
        </div>
      </div>
    </div>
  );
};

export default Custom404;
